import React, { useState }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box'
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Home from '../views/Home';
import MenuDrawer from './MenuDrawer'
import Resume from '../views/Resume';
import { BrowserRouter, Switch, Route} from 'react-router-dom';
import AboutMe from '../views/AboutMe';
import Projects from '../views/Projects';
import NotFound from '../views/NotFound';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import GitHubIcon from '@material-ui/icons/GitHub';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root:{
        animation: `$glow 10s infinite`,
        animationDirection: 'alternate',
        backgroundSize: '300% 300%',
        animationFillMode: 'both',
        background: theme.background.b0
    },
    pageContent: {     
        margin:0,
        padding: theme.spacing(4),
        display: 'flex',
        flexDirection:'column',
        boxSizing:'border-box',
        minHeight: 'calc(100vh - 3em - 20px - 48px)',
    },
    "@keyframes glow":{
        "0%": {
            backgroundPosition: "0% 25%"
        },
        "20%":{
            backgroundPosition: "10% 45%"

        },
        "40%":{
            backgroundPosition: "30% 55%"
        },
        "50%":{
            backgroundPosition: "50% 65%"
        },

        "60%":{
            backgroundPosition: "60% 85%"
        },

        "80%":{
            backgroundPosition: "70% 75%"
        },
        "100%":{
            backgroundPosition: "90% 65%"
        }

    },
    appBar: {
        boxShadow: 'none',
        backgroundColor: "inherit",
        padding:'10px',
        width:'auto'
    },
    menu:{
        justifySelf: "center", 
        alignSelf: "center",
    },
    header: {
      height: "3em",
      display: "inline-flex",
      justifyContent: "flex-start",
      alignContent: "center",
      flexGrow:1,
    },
    headerTitle: {
        flexGrow:1,
        margin:theme.spacing(2),
        alignSelf:'center'
    },
    headerLogo:{
        height:'inherit'
    },
    toolbar:{
        minHeight:'auto',
        padding:theme.spacing(0),
    },
    socialButtons:{
        display:'flex',
        justifyContent:'center'
    }
  }));


function LayoutBase(){
    const [header,setHeader] = useState("")
    const classes = useStyles();
    function handleChange(newValue) {
        setHeader(newValue);
      }
  
    const getPageHeader = (title) =>{
        switch (title) {
            case "resume":
                return("Rèsumè")
            case "about_me":
                return("About Me")
            case "projects":
                return("Projects")
            case "":
                return("")
            default:
                return("404")
        }
    }

    return(
        <React.Fragment>
            <Box className={classes.root}>
                <BrowserRouter>
                    <AppBar position="static" className={classes.appBar}>
                        <Toolbar className={classes.toolbar}>
                            <div className={classes.header}>
                                {getPageHeader(header)!= '' && getPageHeader(header)!= '' &&
                                <img className={classes.headerLogo} src={`https://storage.googleapis.com/${process.env.GCLOUD_STORAGE_BUCKET}/images/logo_no_text.svg`}/>}
                                <Typography variant="h1" className={classes.headerTitle}>
                                    {getPageHeader(header)}
                                </Typography>
                                <MenuDrawer className={classes.menu}/>
                            </div>
                        </Toolbar>
                    </AppBar>
                    <div className={classes.pageContent}>
                    <Switch>
                        <Route path="/resume"  render={()=> <Resume setHeader={(x)=>handleChange(x)}/>} />
                        <Route path="/about_me" render={()=> <AboutMe setHeader={(x)=>handleChange(x)}/>}  />
                        <Route path="/projects"  render={()=> <Projects setHeader={(x)=>handleChange(x)}/>}  />
                        <Route path="/" render={()=> <Home setHeader={(x)=>handleChange(x)}/>} />
                        <Route render={()=> <NotFound setHeader={(x)=>handleChange(x)}/>} />
                    </Switch>
                    </div>
                </BrowserRouter>
                <footer>
                    <div className={classes.socialButtons}>
                    <IconButton href="https://twitter.com/the_anna_bee" target='blank'><TwitterIcon/></IconButton>
                    <IconButton href="https://github.com/annamarieweber" target='blank'><GitHubIcon/></IconButton>
                    <IconButton href="https://www.linkedin.com/in/anna-weber-7273b0137" target='blank'><LinkedInIcon/></IconButton>
                    <IconButton href="https://www.instagram.com/the_anna_bee/" target='blank'><InstagramIcon/></IconButton>
                    </div>
                </footer>
            </Box>
        </React.Fragment>
    )
}

export default LayoutBase;
