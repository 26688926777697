import React, { useEffect}  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      display: 'flex',
    },
    button:{
        border: '2px solid',
        font: theme.typography.button1,
        textTransform: 'none'
    },
    gridRowItem: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    gridColumnItem:{
        padding: theme.spacing(4),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },
    gridContainer: {
        margin:0,
        width:'100%'
    },
    homeLight:{
        border:'none',
        font: theme.typography.home.light,
        color: "#ffffff"
        
    },
    homeBold:{
        font: theme.typography.home.bold,
        color:'#000000'
    },
    introMessage:{
        textAlign:'left',
        "& > div":{

        },
    },

    logo:{
        width: '100%',
        height: 'auto',
        flexShrink:"0",
        flexGrow:"0"
    },

    line1:{
        lineHeight: "150%",
        boxSizing: "border-box",
        animation: `$type1 1.5s steps(12) , $blink 1.5s steps(10)`,
        animationFillMode: "forwards",
        borderRight: '.15em solid transparent',
        display:'block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        font: '3vw Courier New',

    },
    line2:{
        lineHeight: "150%",
        width:'0ch',
        boxSizing: "border-box",
        animation: `$type2 2s steps(22), $blink 2s steps(10)`,
        animationDelay: '1.5s',
        animationFillMode: "forwards",
        display:'block',
        borderRight: '.15em solid transparent',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        font: '3vw Courier New',
    },
    line3:{
        lineHeight: "150%",
        width:'0ch',
        boxSizing: "border-box",
        animation: `$type3 2s steps(20), $blink 2s steps(10)`,
        animationDelay: '3.5s',
        animationFillMode: "forwards",
        display:'block',
        borderRight: '.15em solid transparent',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        font: '3vw Courier New',
    },
    line4:{
        lineHeight: "150%",
        width:'0ch',
        font: '3vw Courier New',
        boxSizing: "border-box",
        animation: `$type4 2s steps(20), $blink 2.5s steps(15)`,
        animationDelay: '5.5s',
        animationFillMode: "forwards",
        display:'block',
        borderRight: '.15em solid transparent',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },

    "@keyframes type1":
     {
        "from": {
            width: "0ch",
        },
        "to": {
            width: "12ch",
        }
      },
      "@keyframes type2":
      {
         "from": {
             width: "0ch",
         },
         "to": {
             width: "21ch",
         }
       },
       "@keyframes type3":
       {
          "from": {
              width: "0ch",
          },
          "to": {
              width: "22ch",
          }
        },
        "@keyframes type4":
        {
           "from": {
               width: "0ch",
           },
           "to": {
               width: "25ch",
           }
         },

      "@keyframes blink":{
        "from, to": { 
            borderColor: "transparent", 
        },
        "50%": { 
            borderColor: "black", 
        }
      }

  }));

function Home(props){
    const handleChange = props.setHeader
    const classes = useStyles();
    const history = useHistory();


    useEffect(()=> {
        handleChange(document.location.pathname.substring(1))
    })



    return(
        <React.Fragment>
            <Box className={classes.root}>
                <Grid container className={classes.gridContainer}
                spacing = {4}
                direction="row"
                justify="center"
                alignItems="center"
                >
                    <Grid item xs={4}>
                        <div className={classes.gridRowItem}>
                            <img className={classes.logo} src={`https://storage.googleapis.com/${process.env.GCLOUD_STORAGE_BUCKET}/images/logo_no_text.svg`}/>

                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container
                        direction="column"
                        >
                        <Grid item className={classes.gridColumnItem}>
                            <div className={classes.introMessage}>
                                <div className={classes.line1}>
                                    <div className={classes.homeLight}>Hello World.</div>
                                </div>
                                <div className={classes.line2}>
                                    <span className={classes.homeLight}>My Name is </span>
                                    <span className={classes.homeBold}>Anna Weber</span>
                                    <span className={classes.homeLight}>.</span>
                                </div>
                                <div className={classes.line3}>
                                    <span className={classes.homeLight}>I am a </span>
                                    <span className={classes.homeBold}>Software Engineer</span>
                                    <span className={classes.homeLight}>.</span>
                                </div>
                                <div className={classes.line4}>
                                    <span className={classes.homeLight}>Living in </span>
                                    <span className={classes.homeBold}>San Francisco, CA</span>
                                    <span className={classes.homeLight}>.</span>
                                </div>
                            </div>
                        </Grid>
                        <Grid item className={classes.gridColumnItem}>
                            <Button size="large" variant="outlined" className={classes.button} onClick={()=>history.push('/projects')}>See My Work</Button>
                        </Grid>
                        </Grid>
                    </Grid>
                </Grid> 
            </Box>
        </React.Fragment>
    )
}

export default Home;