import React, { useEffect}  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      display: 'flex',
    },
    button:{
        border: '2px solid',
        font: theme.typography.button1,
        textTransform: 'none'
    },
    gridRowItem: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    gridColumnItem:{
        padding: theme.spacing(4),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },
    gridContainer: {
        margin:0,
        width:'100%'
    },
    homeLight:{
        border:'none',
        font: theme.typography.home.light,
        color: "#ffffff"
        
    },
    homeBold:{
        font: theme.typography.home.bold,
        color:'#000000'
    },
    introMessage:{
        textAlign:'left',
        "& > div":{

        },
    },

    logo:{
        width: '100%',
        height: 'auto',
        flexShrink:"0",
        flexGrow:"0"
    },

    line1:{
        boxSizing: "border-box",
        animation: `$type1 1.5s steps(12) , $blink 1.5s steps(10)`,
        animationFillMode: "forwards",
        borderRight: '.15em solid transparent',
        display:'block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        font: '3vw Courier New',

    },
    line2:{
        width:'0ch',
        boxSizing: "border-box",
        animation: `$type2 2s steps(22), $blink 2s steps(10)`,
        animationDelay: '1.5s',
        animationFillMode: "forwards",
        display:'inline-block',
        borderRight: '.15em solid transparent',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        font: '3vw Courier New',
    },
    line3:{
        width:'0ch',
        boxSizing: "border-box",
        animation: `$type3 2s steps(20), $blink 2s steps(10)`,
        animationDelay: '3.5s',
        animationFillMode: "forwards",
        display:'block',
        borderRight: '.15em solid transparent',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        font: '3vw Courier New',
    },


    "@keyframes type1":
     {
        "from": {
            width: "0ch",
        },
        "to": {
            width: "12ch",
        }
      },
      "@keyframes type2":
      {
         "from": {
             width: "0ch",
         },
         "to": {
             width: "30ch",
         }
       },
       "@keyframes type3":
       {
          "from": {
              width: "0ch",
          },
          "to": {
              width: "24ch",
          }
        },

      "@keyframes blink":{
        "from, to": { 
            borderColor: "transparent", 
        },
        "50%": { 
            borderColor: "black", 
        }
      }

  }));

function NotFound(props){
    const handleChange = props.setHeader
    const classes = useStyles();
    const history = useHistory();


    useEffect(()=> {
        handleChange(document.location.pathname.substring(1))
    })



    return(
        <React.Fragment>
            <Box className={classes.root}>
                <Grid container className={classes.gridContainer}
                spacing = {4}
                direction="row"
                justify="center"
                alignItems="center"
                >
                    <Grid item xs={4}>
                        <div className={classes.gridRowItem}>
                            <img className={classes.logo} src={`https://storage.googleapis.com/${process.env.GCLOUD_STORAGE_BUCKET}/images/logo_no_text.svg`}/>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container
                        direction="column"
                        >
                        <Grid item className={classes.gridColumnItem}>
                                <Typography className={classes.line1}>Bummer...</Typography>
                                <Typography className={classes.line2}> the page you are looking for</Typography>
                                <Typography className={classes.line3}> does not seem to exist.</Typography>
                        </Grid>
                        </Grid>
                    </Grid>
                </Grid> 
            </Box>
        </React.Fragment>
    )
}

export default NotFound;