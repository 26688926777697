import React from 'react';
import { createMuiTheme,ThemeProvider} from '@material-ui/core/styles';
import LayoutBase from './components/LayoutBase';


function App() {
  const theme = createMuiTheme({
    background:{
      b0:'radial-gradient(#34c6eb, #05a1f0, #0084ff,#054bf0)',
    } ,
    palette: {
      primary: {
        main: "#5c585b",
        blue: "#05a1f0"
      },
      secondary: {
        main: "#5972ba",
      },
      black: {
        main: "#000000"
      },
      white: '#ffffff',
      background:{
        paper:'rgba(255, 255, 255, 0.9)'

      }
    },
    typography: {
      h1:{
        fontFamily:'Krungthep, Coda, Courier',
        fontSize:'2.5em',
        textTransform: 'uppercase',
        letterSpacing: '.1vw',
      },
      h5:{
        textAlign:'center'
      },
      h3:{
        fontFamily:'Helvetica Neue',
        fontSize:'1.5em',
        fontWeight: 'bold',
        lineHeight: '2',
        letterSpacing: '.8vh',
      
      },
      subtitle1: {
        fontSize: 12,
      },
      h4: {
        fontFamily:'Courier New',
        fontSize:'1.7em',
        fontWeight: 600,
        lineHeight: '1.5',
        textTransform: 'none',
        letterSpacing: '1vh',
      },
      h5: {
        fontFamily:'Courier New',
        fontSize:'1.7em',
        fontWeight: 400,
        lineHeight: '1.0',
        textTransform: 'none',
        letterSpacing: '1vh',
        color:'white'
      },
      body1: {
        fontFamily:'Helvetica Neue',
        fontSize:'2.5vw',
        fontWeight: 300,
        lineHeight: '1.5',
      },
      h6: {
        fontFamily:'Helvetica Neue',
        fontSize:'1.0em',
        fontWeight: 400,
        lineHeight: '2.5'
      },
      body2: {
	fontFamily: 'Helvetica Neue',
	fontSize: '1.0em',
	color: 'white',
	fontWeight: 200
      },
      button1: '2vw Helvetica Neue',
      home: {
        light: '3vw Courier New',
        bold: '3vw Helvetica Neue',
      },
    },
    spacing: factor => `${0.25 * factor}rem`
  });

  return (
  <ThemeProvider theme={theme}>
   <LayoutBase/>
  </ThemeProvider>
  
  );
}



export default App;
