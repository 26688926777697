import React, { useEffect }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
import { Grid } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      display: 'flex',
      margin: theme.spacing(2)
    },
    nameDefinition:{
        display:'flex',
        flexDirection: 'column',
        alignContent:'center',
        backgroundColor:'rgb(255 255 255 / 22%)',
        borderRadius:'1em',
        justifyContent:'center',
        boxSizing:'border-box',
        margin:'auto',
        width:'auto', 
        padding: '1em',

    },
    profileImage:{
        maxWidth:'100%',
        height:'auto',
        maxHeight: '80vh',
        width:'auto',
        borderRadius:'10%',
        alignSelf:'center',
        border: `3px solid ${theme.palette.white}`
    },
    summarySection:{
        backgroundColor:'rgb(255 255 255 / 22%)',
        borderRadius: '1em',
        width:'auto',
        margin:'auto',
        display:'flex',
        alignItems:'center',
        alignContent:'center',
        justifyContent:'space-around',
    },
    summary:{
        fontSize:'1.2em',
        textAlign:'left'   
    },
    summaryList:{
	fontSize:'1.0em',
	textAlign:'left'
    },
    bolded:{
        color:'black'
    } 
  }));
  
const aboutMeMessage1 = "I am an energetic engineer with a passion for producing reliable software. Nothing excites me more than solving challenging problems, and I am constantly seeking out opportunities to do this very thing. Outside of work, I can usually be found in one of three places:"
const aboutMeMessage2 = "outside running and adventuring with my adorable goldendoodle Pascal"
const aboutMeMessage3 = "at my computer working on one side project or another, or" 
const aboutMeMessage4 = "on the floor of my apartment surrounded by papers covered in math problems and my attempts to solve them."
const aboutMeMessage5 = "My favorite thing to eat for breakfast is ice cream (yes, you read that right), and when it comes to sandwiches, I am an ingredient rebel but a structural purist!"

function AboutMe(props){
    const handleChange = props.setHeader
    const classes = useStyles();

    useEffect(()=> {
        handleChange(document.location.pathname.substring(1))
    })

    return(
        <React.Fragment>
            <Grid container spacing={8}>
            <Grid item xs={12}  >
                <Grid item xs={12} className={classes.nameDefinition} >
                        <Typography variant='h4'>Anna Weber</Typography>
                        <Typography variant='h5'>an-na web-er [ænə wɛbɚ] <i>pr.</i> <b className={classes.bolded}>1</b> Innovative problem solver <b className={classes.bolded}>2</b> Full stack developer <b className={classes.bolded}>3</b> Creative thinker <b className={classes.bolded}> 4</b> Math nerd</Typography>
                </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={8} className={classes.summarySection}>
                            <Grid item md={4} >
                                <img className={classes.profileImage} src={`https://storage.googleapis.com/${process.env.GCLOUD_STORAGE_BUCKET}/images/about2.jpg`}/>
                            </Grid>
                            <Grid item md={8}>
                                <Typography variant='body1' className={classes.summary}>{aboutMeMessage1}</Typography>
				<ol>
			          <li><Typography variant='body2' component="span" className={classes.summaryList}>{aboutMeMessage2}</Typography></li>
				  <li><Typography variant='body2' component="span" className={classes.summaryList}>{aboutMeMessage3}</Typography></li>
  				  <li><Typography variant='body2' component="span" className={classes.summaryList}>{aboutMeMessage4}</Typography></li>
			        </ol>
                                <Typography variant='body1' className={classes.summary}>{aboutMeMessage5}</Typography>
                            </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default AboutMe;
