import React, { useState }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.black,
        margin: theme.spacing(8),
    },
    menuText:{
        font: theme.typography.h1.fontFamily,
        fontSize: '3em',
        color: theme.palette.primary.blue,
        textAlign: 'right'
    },
    "@media only screen and (max-width: 600px)":
    {
       menuText: {
         textAlign: 'center'
       }
   },
    menuButton: {
        margin: 0,
        alignSelf: 'center',
    },
    list: {
        width: '100%',
        minWidth: '300px',
    },

    fullList: {
        width: 'auto',
    }
}));

function MenuDrawer(){
    const [menuOpen,setMenuOpen] = useState(false)
    const classes = useStyles()
    const history = useHistory();

    const locations = ['','about_me','projects','resume']

    const toggleDrawer = () => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setMenuOpen(!menuOpen);
    };

    const handleClick = (index) => {
        history.push(`/${locations[index]}`)};
    
    const list = () => (
        <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer()}
            onKeyDown={toggleDrawer()}
        >
            <List>
            {['Home','About Me','Projects','Rèsumè'].map((text, index) => (
                <ListItem button key={text} className={classes.menuButton} onClick={()=>handleClick(index)}>
                    <ListItemText primary={text} className={classes.menuText}/>
                </ListItem>
            ))}
            </List>
        </div>
    );
    
    return (
        <React.Fragment key='right'>
            <IconButton onClick={toggleDrawer()} edge="end" className={classes.menuButton} color="inherit" aria-label="menu">
                <MenuIcon/>
            </IconButton>
            { Math.max(window.screen.width, window.innerWidth) > 600?
            <Drawer anchor='right' open={menuOpen} onClose={toggleDrawer()}>
            {list('right')}
            </Drawer>:
             <Drawer anchor='top' open={menuOpen} onClose={toggleDrawer()}>
             {list('top')}
             </Drawer>}
        </React.Fragment>
    );
}
export default MenuDrawer
