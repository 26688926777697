import React, { useEffect }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
import { Grid, Card, CardContent, CardMedia, CardActions, Button } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      display: 'flex',
      margin: theme.spacing(2)
    },
    gridContainer:{
        alignItems:'stretch'
    },
    gridItem:{
        alignSelf:'stretch'
    },
    cardRoot:{
        borderRadius: '10px',
	display: 'flex',
	flexDirection: 'column',
        flexGrow: 1,
        height:'100%',
        backgroundColor:'rgb(255 255 255 / 22%)'
    },
    cardContent:{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    cardMedia:{
	maxHeight: '250px',
	maxWidth: '350px',
	width: 'fit-content',
	justifyContent: 'center',
	alignItems: 'center',
	display: 'flex',
        borderRadius: '5px',
        marginBottom:'1vh'
    },
    sectionBody:{
	fontSize: '1.2em',
        letterSpacing:'normal',
        textAlign:'justify'
    },
    sectionHeader:{
        color:theme.palette.white,
        fontSize:"1.5em",
        fontWeight:'500',
        letterSpacing:'normal',
        textAlign:'center'
    },
    button:{
        justifySelf:'flex-end'
    },
    cardActions:{
        alignContent:'center',
        justifyContent:'center'
    }
  }));

function Projects(props){
    const handleChange = props.setHeader
    const classes = useStyles();


    useEffect(()=> {
        handleChange(document.location.pathname.substring(1))
    })



    return(
        <React.Fragment>
            <Grid container spacing={5} className={classes.gridContainer}>
            <Grid item lg={4} md={6} sm={6} xs={12} className={classes.gridItem}>
                <Card className={classes.cardRoot}>
                    <CardContent className={classes.cardContent}>
                    <Typography className={classes.sectionHeader} gutterBottom variant="h5" component="h2">MSSE Capstone</Typography>
                    <CardMedia className={classes.cardMedia} component="img"
                    src={`https://storage.googleapis.com/${process.env.GCLOUD_STORAGE_BUCKET}/images/capstone.png`}/>
		    <Typography className={classes.sectionBody} variant="body1" component="p">{"This capstone project improved an io extension library for pymatgen by implementing flexible parameterization for better accuracy in high throughput molecular simulations, addressing the challenge of modeling atoms and molecules with charge changes in different environments. An interface was developed to allow for the use of polarizable force fields, leading to more accurate simulations and predictions of functional materials, expanding the capabilities of the Materials Project for materials design and discovery."}
		    </Typography>
                    </CardContent>
                    <CardActions className={classes.cardActions}>
                        <Button size="large"  variant="outlined" className={classes.button} href="https://github.com/orionarcher/pymatgen-io-openmm" target="_blank">
                            View it on github
                        </Button>
                    </CardActions>
                 </Card>
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12} className={classes.gridItem}>
                <Card className={classes.cardRoot}>
                    <CardContent className={classes.cardContent}>
                    <Typography className={classes.sectionHeader} gutterBottom variant="h5" component="h2">SCFChem</Typography>
                    <CardMedia className={classes.cardMedia} component="img"
                    src={`https://storage.googleapis.com/${process.env.GCLOUD_STORAGE_BUCKET}/images/scfchem.png`}/>
                    <Typography className={classes.sectionBody} variant="body1" component="p">{"SCFChem is a C++ tool for calculating nuclear energy, force, and other properties of molecules using the Self-Consistent Field (SCF) method. This package is designed for use in quantum chemistry and computational materials science, and allows users to easily compute and analyze the electronic structure of molecules."}  
		    </Typography>
                    </CardContent>
                    <CardActions className={classes.cardActions}>
                        <Button size="large"  variant="outlined" className={classes.button} href="https://scfchem.com" target="_blank">
                            Read The Docs
                        </Button>
                    </CardActions>
                 </Card>
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12} className={classes.gridItem}>
                <Card className={classes.cardRoot}>
                    <CardContent className={classes.cardContent}>
                    <Typography className={classes.sectionHeader} gutterBottom variant="h5" component="h2">Bellweather Health</Typography>
                    <CardMedia className={classes.cardMedia} component="video"
                    src={`https://storage.googleapis.com/${process.env.GCLOUD_STORAGE_BUCKET}/images/bellweather.mp4`} controls muted autoPlay/>
                    <Typography className={classes.sectionBody} variant="body1" component="p">{"\"Bellweather\", was created as a project for the UC Berkeley iHackHealth Appathon. The team was led by Stefano Leitner, UCSF and work was done in collaboration with Jakob Kraiger, Kailin Li, Varsha Madapoosi, Nikki Catangay, Jin Wei Wong, Ayushi Batwara, Steven Gunarso, and Meera Vinod. The app integrates health, demographic, and weather forecasting data to pinpoint individuals who are vulnerable to adverse health effects caused by severe weather conditions. By doing so, it enables targeted preventive measures to be taken. The initiative aims to tackle the health consequences and expenses resulting from climate change."}
		    </Typography>
                    </CardContent>
                    <CardActions className={classes.cardActions}>
                        <Button size="large"  variant="outlined" className={classes.button} href="https://medium.com/the-coleman-fung-institute/dermscan-team-wins-2022-ihackhealth-appathon-58b900d652b2" target="_blank">
                            Learn More
                        </Button>
                    </CardActions>
                 </Card>
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12} className={classes.gridItem}>
                <Card className={classes.cardRoot}>
                    <CardContent className={classes.cardContent}>
                    <Typography className={classes.sectionHeader} gutterBottom variant="h5" component="h2">BoldOpen Site Design</Typography>
                    <CardMedia className={classes.cardMedia} component="video"
                    src={`https://storage.googleapis.com/${process.env.GCLOUD_STORAGE_BUCKET}/images/boldopen1.mov`} controls muted autoPlay/>
                    <Typography className={classes.sectionBody} variant="body1" component="p">
                    Worked alongside partners from AURI, MBOLD, and Target to design and develop a website for a reverse pitch event aimed at finding solutions for problems facing today's agriculture industry.</Typography>
                    </CardContent>
                    <CardActions className={classes.cardActions}>
                        <Button size="large"  variant="outlined" className={classes.button} href="https://boldopenmn.com/" target="_blank">
                            See it Live
                        </Button>
                    </CardActions>
                 </Card>
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12} className={classes.gridItem}>
                <Card className={classes.cardRoot}>
                    <CardContent className={classes.cardContent}>
                    <Typography className={classes.sectionHeader} gutterBottom variant="h5" component="h2">Columbus Trees Data Viz</Typography>
                    <CardMedia className={classes.cardMedia} component="img"
                    image={`https://storage.googleapis.com/${process.env.GCLOUD_STORAGE_BUCKET}/images/trees.jpeg`}/>
                    <Typography className={classes.sectionBody} variant="body1"  component="p">Javascript project leveraging d3.js to display up to date visualizations about the health of Columbus trees relative to their locations using data polled from the Columbus parks API.</Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12} className={classes.gridItem}>
                <Card className={classes.cardRoot}>

                <CardContent className={classes.cardContent}>
                    <Typography className={classes.sectionHeader} gutterBottom variant="h5" component="h2">Running Safety Web App</Typography>
                    <CardMedia className={classes.cardMedia} component="img"
                    image={`https://storage.googleapis.com/${process.env.GCLOUD_STORAGE_BUCKET}/images/trailz3.png`}/>
                    <Typography className={classes.sectionBody} variant="body1"  component="p">Web application built as a hackathon project. Created to be used for crowd sourced reporting of running safety hazards and other trail info. Built using node.js and hosted with google firebase.</Typography>
                </CardContent>
                </Card>
            </Grid>
          </Grid>
        </React.Fragment>
    )
}

export default Projects;
