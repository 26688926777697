import React, { useEffect }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
import { Grid } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      display: 'flex',
      margin: theme.spacing(2)
    },
    gridSection: {
      margin: '10px',
      marginBottom: '25px',
      padding: '15px',
      border: 'solid 1px',
      borderRadius: '15px',
      borderColor: theme.palette.black
    },
  gridHeader: {
    marginLeft: '15px'
  },
    sectionHeader:{
      display:'flex',
      fontSize: '1.25em',
      fontWeight: 400,
      color: theme.palette.black
    },
    sectionBody:{
      display:'flex',
      color: theme.palette.white
    }
  }));

function Resume(props){
    const handleChange = props.setHeader
    const classes = useStyles();

    
    useEffect(()=> {
      handleChange(document.location.pathname.substring(1))
  })



    return(
        <React.Fragment>
          <Grid container >
            <Grid item xs={12}>
	      <Typography variant="h3" className={classes.gridHeader}>Anna Marie Weber</Typography>
	      <div className={classes.gridSection}>
		<Typography className={classes.sectionHeader} variant='body1'>Phone</Typography>
		<Typography className={classes.sectionBody} variant='body2'>(614)-354-8876</Typography>
		<Typography className={classes.sectionHeader} variant='body1'>Email</Typography>
		<Typography className={classes.sectionBody} variant='body2'>annaweberdev@gmail.com</Typography>
	      </div>
	    </Grid>
            <Grid item xs={12}>
              <Typography variant="h3" className={classes.gridHeader}>Education</Typography>
	      <div className={classes.gridSection}>
		<Typography className={classes.sectionHeader} variant='body1'>University of California Berkeley</Typography>
		<Typography className={classes.sectionBody} variant='body2'>2021 - Present</Typography>
		<Typography className={classes.sectionBody} variant='body2'>M.S. Molecular Science and Software Engineering</Typography>
              <br/>
		<Typography className={classes.sectionHeader} variant='body1'>The Ohio State University</Typography>
		<Typography className={classes.sectionBody} variant='body2'>2015 - 2019</Typography>
		<Typography className={classes.sectionBody} variant='body2'>{"B.S. Computer Science and Engineering with a Minor in Studio Art"}</Typography>
	      </div>      
            </Grid>
            <Grid item xs={12}>
	      <Typography variant="h3" className={classes.gridHeader}>Employment</Typography>
	      <div className={classes.gridSection}>
		<div>
		  <Typography className={classes.sectionHeader} variant='body1'>Software Engineer (March 2023 - Present)</Typography>
		  <Typography className={classes.sectionBody} variant='body2'>Startup - San Francisco, CA</Typography>
		</div>
		<br/>
		<div>
		  <Typography className={classes.sectionHeader} variant='body1'>Software Engineer (June 2021 - March 2023)</Typography>
		  <Typography className={classes.sectionBody} variant='body2'>Upstart - San Mateo, CA</Typography>
		</div>
		<br/>
		<div>
		  <Typography className={classes.sectionHeader} variant='body1'>Software Engineer (September 2019 - May 2021)</Typography>
		  <Typography className={classes.sectionBody} variant='body2'>Target Corporation - Minneapolis, MN</Typography>
		</div>
		<br/>
		<div>
		  <Typography className={classes.sectionHeader} variant='body1'>Software Engineering intern (June 2018 - August 2018)</Typography>
		  <Typography className={classes.sectionBody} variant='body2'>Target Corporation - Minneapolis, MN</Typography>
		</div>
	      </div>
            </Grid>
            <Grid item xs={12}>
	      <Typography variant="h3" className={classes.gridHeader}>Projects</Typography>
		<div className={classes.gridSection}>
		  <Typography className={classes.sectionHeader} variant='body1'>{"Parameterization interface for pymatgen.io.openmm"}</Typography>
		  <Typography className={classes.sectionBody} variant='body2'>MSSE Spring Capstone 2023</Typography>
		  <br/>
		  <Typography className={classes.sectionHeader} variant='body1'>SCFChem</Typography>
		  <Typography className={classes.sectionBody} variant='body2'>Graduate School Project 2022</Typography>
		  <br/>
		  <Typography className={classes.sectionHeader} variant='body1'>Bellweather Health</Typography>
		  <Typography className={classes.sectionBody} variant='body2'>iHackHealth Appathon 2nd Place Project - 2022</Typography>
		  <br/>
		  <Typography className={classes.sectionHeader} variant='body1'>Bold Open Minnesota Site Design</Typography>
		  <Typography className={classes.sectionBody} variant='body2'>Non Profit Involvement 2020</Typography>
		  <br/>
		  <Typography className={classes.sectionHeader} variant='body1'>Columbus Trees Data Visualization using D3.js</Typography>
		  <Typography className={classes.sectionBody} variant='body2'>Internet Art Project 2018</Typography>
		  <br/>
		  <Typography className={classes.sectionHeader} variant='body1'>Running Safety Web App</Typography>
		  <Typography className={classes.sectionBody} variant='body2'>HackOhio Hackathon project 2018</Typography>
	      </div>      
	    </Grid>
          </Grid>
        </React.Fragment>
    )
}

export default Resume;
